import styled from '@emotion/styled'
import Text from '../common/Text'

export const Heading2 = styled(Text)`
  color: ${props => (props.color ? props.color : props.theme.color.daiBlack)};
  font-size: 40px;
  line-height: 48px;
`

export default Heading2
