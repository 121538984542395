import React from 'react'
import styled from '@emotion/styled'
import { StaticImage } from 'gatsby-plugin-image'

import LayoutDAI from '../../../components/LayoutDAI'

import Section from '../../../components/dai/Section'
import IconCard from '../../../components/dai/IconCard'
import CalloutCard from '../../../components/dai/CalloutCard'
import { Heading1 } from '../../../components/dai/Heading1'
import { Heading2 } from '../../../components/dai/Heading2'
import { Text } from '../../../components/dai/Text'
import Columns from '../../../components/dai/Columns'
import Column from '../../../components/dai/Column'
import PageNavigation from '../../../components/dai/PageNavigation'
import { BoldText } from '../../../components/dai/BoldText'

import SEO from '../../../components/SEO'
import Space from '../../../components/common/Space'

import BrokenLink from '../../../images/dai/icons/assets/broken-link.svg'
import BrokenComputer from '../../../images/dai/icons/assets/broken-computer.svg'
import LaptopError from '../../../images/dai/icons/assets/laptop-error.svg'
import Erase from '../../../images/dai/icons/assets/erase.svg'

const DesktopOnly = styled.div`
  @media (max-width: 1199px) {
    display: none;
  }
`

const MobileOnly = styled.div`
  @media (min-width: 1200px) {
    display: none;
  }
`

export default function LinkAccessibilityTips() {
  return (
    <LayoutDAI showKeyInsights pagePath={{ parent: 'key-insights', child: 'link-accessibility-tips' }}>
      <SEO
        title="Link Accessibility Tips | 2023 Digital Accessibility Index"
        pathname="/digital-accessibility-index/key-insights/link-accessibility-tips/"
        desc="After scanning 357 million links, we found that 90% of sites had at least one page with an inaccessible link, and 64% of pages had at least one inaccessible link."
        bannerUrl="https://images.prismic.io/audioeye-web/789492fd-2501-42d9-b01a-97deba3d5c94_form-accessibility-links.png?auto=compress,format"
        imageAlt="Stylized illustration showing both accessible and inaccessible links."
        canonical={`https://${
          process.env.GATSBY_ACTIVE_ENV === 'production' ? 'www' : 'beta'
        }.audioeye.com/digital-accessibility-index/key-insights/link-accessibility-tips/`}
      />
      <Section paddingTop="md" constraint="lg" innerConstraint="md" backgroundColor="orange200">
        <Text largeCapsInter color="orange900">
          LINK ACCESSIBILITY TIPS
        </Text>
        <Space height={24} />
        <Heading1 heading1 alt aria-label="Link Accessibility Tips">
          Vague links can make browsing websites an (unwelcome) adventure.
        </Heading1>
      </Section>
      <Section paddingTop="md" constraint="lg" innerConstraint="md" backgroundColor="orange200">
        <Text>
          When it comes to page links, most organizations default to short phrases like <BoldText>Shop All</BoldText> or{' '}
          <BoldText>Learn More</BoldText>.
        </Text>
        <Space height={32} />
        <Text>
          And while this approach might be good enough for people who can take in the surrounding information on a page
          at a glance, it does little for screen reader users who typically browse a page by skipping from link to link.
        </Text>
        <Space height={32} />
        <Text>
          In order to be accessible, all page links should tell users exactly where clicking it will take them — either
          in the actual link title or by adding a link title that screen readers and other assistive technology can read
          out loud.
        </Text>
      </Section>
      <Section paddingTop="md" constraint="lg" backgroundColor="orange200">
        <CalloutCard>
          <Heading2 heading2 className="asH3" color="purple800">
            64% of pages had links that lacked critical context for people with disabilities.
          </Heading2>
          <Space height={32} />
          <Text bodyMedium color="purple800">
            Our automated scan found that nearly 11 million links — or roughly 5 per page scanned — were inaccessible to
            people with visual or cognitive impairments, making it hard for them to quickly navigate between pages.
          </Text>
          <Space height={32} />
          <DesktopOnly>
            <StaticImage
              src="../../../images/dai/callout-card/broken-links-desktop.png"
              alt="5 broken links to represent roughly 5 inaccessible links found per page scanned."
            />
          </DesktopOnly>
          <MobileOnly>
            <StaticImage
              src="../../../images/dai/callout-card/broken-links-mobile.png"
              alt="5 broken links to represent roughly 5 inaccessible links found per page scanned."
            />
          </MobileOnly>
        </CalloutCard>
      </Section>
      <Section paddingTop="md" constraint="lg" innerConstraint="md" backgroundColor="orange200">
        <Text>
          After scanning 357 million links, it’s clear that{' '}
          <BoldText>link accessibility is a persistent issue for most enterprise sites</BoldText>. In fact, our scan
          revealed that 90% of sites had at least one page with an inaccessible link, and 64% of pages had at least one
          inaccessible link.
        </Text>
      </Section>
      <Section paddingTop="md" constraint="lg" backgroundColor="orange200">
        <Columns unorderedList>
          <Column size={8} listItem>
            <IconCard noCard>
              <BrokenLink />
              <Space height={16} />
              <Text xLargeBodyBold>90%</Text>
              <Space height={16} />
              <Text smallBodyMedium>of sites had at least one page with an inaccessible link</Text>
            </IconCard>
          </Column>
          <Column size={8} listItem>
            <IconCard noCard>
              <BrokenComputer />
              <Space height={16} />
              <Text xLargeBodyBold>64%</Text>
              <Space height={16} />
              <Text smallBodyMedium>of pages had at least one inaccessible link</Text>
            </IconCard>
          </Column>
          <Column size={8} listItem>
            <IconCard noCard>
              <LaptopError />
              <Space height={16} />
              <Text xLargeBodyBold>~5</Text>
              <Space height={16} />
              <Text smallBodyMedium>number of links per page that are not clear to users</Text>
            </IconCard>
          </Column>
        </Columns>
      </Section>
      <Section paddingTop="lg" constraint="lg" innerConstraint="md" backgroundColor="orange200">
        <Heading2 heading2 className="asH3">
          Images as links have their own set of rules.
        </Heading2>
        <Space height={32} />
        <Text>
          Link accessibility doesn’t end with text links, either. If an image is being used to link to another page, the
          alt text should describe what will happen when the image is clicked, rather than what it looks like.
        </Text>
        <Space height={32} />
        <Text>
          For example, the alt text for an image of a headset that links to a customer support page should say “Contact
          Support” instead of “headset.” During our scan, the average page had 9 images as links — and 21% of them were
          improperly labeled.
        </Text>
        <Space height={32} />
        <CalloutCard backgroundColor="purple800" smallCard>
          <Columns>
            <Column size={4} tabletSize={6}>
              <Erase />
            </Column>
            <Column size={20} tabletSize={18}>
              <Text xLargeBodyBold color="white">
                21%
              </Text>
              <Space height={12} />
              <Text bodyMedium color="white">
                of images as links are improperly labeled, meaning they don’t tell screen reader users where clicking
                the image will take them.
              </Text>
            </Column>
          </Columns>
        </CalloutCard>
      </Section>
      <Section paddingTop="lg" constraint="lg" innerConstraint="md" backgroundColor="orange200">
        <Text largeCapsInter color="orange900">
          BEST PRACTICES
        </Text>
        <Space height={32} />
        <Heading2 heading2 className="asH3">
          The ‘link’ between accessibility and descriptive links
        </Heading2>
        <Space height={32} />
        <Text>
          To understand why descriptive links are so important for accessibility, it helps to understand how screen
          reader users typically navigate a web page.
        </Text>
        <Space height={32} />
        <Text>
          Many screen reader users will skim through the entire page on their first visit, using their keyboard to jump
          between links and headers. It’s similar to the way a sighted user might scroll through a page, glancing at
          links and headers to figure out what the page covers — and where they want to click next.
        </Text>
        <Space height={32} />
        <Text>
          If you think about driving, links on a page are kind of like GPS directions. A directive to “Turn!” isn’t all
          that helpful, whereas “In 500 feet, turn left onto Main Street” tells you exactly what to expect.
        </Text>
      </Section>
      <Section paddingTop="md" constraint="lg" backgroundColor="orange200">
        <StaticImage
          src="../../../images/dai/content/map.png"
          alt="Stylized map with a turn-by-turn directions interface that reads in 500 feet, turn left onto Main street."
        />
      </Section>
      <Section paddingTop="md" constraint="lg" innerConstraint="md" backgroundColor="orange200">
        <Text>
          If the links on your page aren’t descriptive, it forces screen readers to go back and read the text
          surrounding a link to (hopefully) understand where it’s pointing, slowing them down and impacting the user
          experience.
        </Text>
      </Section>
      <Section paddingTop="lg" paddingBottom="lg" constraint="lg" backgroundColor="orange200">
        <Columns unorderedList>
          <Column size={12} listItem>
            <Text>
              Example of a <BoldText>non-descriptive</BoldText> link:
            </Text>
            <Space height={32} />
            <StaticImage
              src="../../../images/dai/examples/car-inaccessible.png"
              alt="A car with a faux link that reads “Click here” with an error symbol next to it to represent an inaccessible link."
            />
          </Column>
          <Column size={12} listItem>
            <Text>
              Example of a <BoldText>descriptive</BoldText> link:
            </Text>
            <Space height={32} />
            <StaticImage
              src="../../../images/dai/examples/car-accessible.png"
              alt="A car with a faux link that reads “View our collection of model cars” with a checkmark next to it to represent an accessible link."
            />
          </Column>
        </Columns>
      </Section>
      <PageNavigation
        previousPageLink="/digital-accessibility-index/key-insights/image-accessibility-tips/"
        previousPageText="Image Accessibility Tips"
        nextPageLink="/digital-accessibility-index/key-insights/form-accessibility-tips/"
        nextPageText="Form Accessibility Tips"
      />
    </LayoutDAI>
  )
}
