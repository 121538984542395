/* eslint-disable indent */
import React from 'react'
import styled from '@emotion/styled'

const Wrapper = styled.div`
  ${props =>
    !props.noCard &&
    `
    border-radius: 12px;
    background-color: ${props.theme.color.orange300};
    padding: 28px;
  `}

  ${props =>
    props.flex &&
    `
    display: flex;
    align-items: center;
    gap: 12px;
  `}

  a {
    display: inline-block;
  }

  @media (max-width: 768px) {
    a {
      margin: 0;
    }
  }
`

const IconCard = ({ children, noCard, flex }) => (
  <Wrapper flex={flex} noCard={noCard}>
    {children}
  </Wrapper>
)

export default IconCard
