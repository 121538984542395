/* eslint-disable indent */
import React from 'react'
import styled from '@emotion/styled'
import { Button } from './Button'

const Wrapper = styled.section`
  padding: 96px 112px;
  width: 100%;

  @media (max-width: 1050px) {
    padding: 64px 48px;
  }

  @media (min-width: 1200px) and (max-width: 1439px) {
    padding: 96px 56px;
  }
`

const Constraint = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 844px; // 40px less to account for the 2 arrows that are both 20px
  margin: 0 auto;

  @media (min-width: 1501px) {
    max-width: 1120px;
  }

  @media (max-width: 768px) {
    max-width: 100%;
  }
`

const PageNavigation = ({ previousPageLink, previousPageText, nextPageLink, nextPageText }) => (
  <Wrapper>
    <Constraint>
      {previousPageLink && previousPageText ? (
        <Button text={`Back: ${previousPageText}`} to={previousPageLink} buttonType="reverse link CTA" />
      ) : (
        // eslint-disable-next-line
        <div></div>
      )}
      {nextPageLink && nextPageText && (
        <Button text={`Next: ${nextPageText}`} to={nextPageLink} buttonType="link CTA" />
      )}
    </Constraint>
  </Wrapper>
)

export default PageNavigation
