/* eslint-disable indent */
import React from 'react'
import styled from '@emotion/styled'

const Wrapper = styled.div`
  border-radius: ${props => (props.smallCard ? '12px' : props.largeCard ? '40px' : '24px')};
  padding: ${props => (props.smallCard ? '24px' : props.largeCard ? '64px' : '48px')};
  background-color: ${props =>
    props.backgroundColor ? props.theme.color[props.backgroundColor] : props.theme.color.orange300};
  width: 100%;
  ${props =>
    props.higherPosition &&
    `
      position: relative;
      margin-top: -100px;
  `}

  @media (max-width: 768px) {
    ${props =>
      props.higherPosition &&
      `
        position: relative;
        margin-top: -150px;
    `}
  }

  @media (max-width: 480px) {
    ${props =>
      props.higherPosition &&
      `
        position: relative;
        margin-top: -278px;
    `}

    ${props =>
      props.largeCard &&
      `
        padding: 28px;
    `}
  }
`

const CalloutCard = ({ children, higherPosition, backgroundColor, smallCard, largeCard }) => (
  <Wrapper
    higherPosition={higherPosition}
    backgroundColor={backgroundColor}
    smallCard={smallCard}
    largeCard={largeCard}
  >
    {children}
  </Wrapper>
)

export default CalloutCard
